$(document).ready(function() {
    $('.calc-currencies').slick({
        infinite: true,
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical : true,
        centerPadding: '0px',
        prevArrow: ' ',
        nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="Next" tabindex="0" role="button"><i class="fa fa-chevron-down"></i></button>'
    }).on('afterChange', function(event, slick, currentSlide, nextSlide){
        calculate();
    });

    $('.valutas').slick({
        infinite: true,
        slidesToShow: 8,
        slidesToScroll: 1,
        centerPadding: '0px',
        prevArrow: ' ',
        nextArrow: '<button type="button" data-role="none" class="slick-next slick-main-next" aria-label="Next" tabindex="0" role="button"><i class="fa fa-chevron-right"></i></button>'
    });

    calculate();

});

/* ---------------------------------------
 *  AJAX REQUESTS
 *  ---------------------------------------*/

$.ajaxSetup({ headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') } });

function calculate() {
    var amount = $('#calc-amount').val();
    var currency = $('.slick-center').html();

    $.post( baseUrl+"/calculate", { amount : amount,  currency : currency} )
        .done(function(data) {
            console.log(data);
            $('#calc-res-buy').html(data.buy);
            $('#calc-res-sell').html(data.sell);
        }, "json");
}

function toggle_menu() {
    $('#mobile-main').toggle();
}